<template>
    <div>
        <div id="printTest">
            <div class="ggdetailbox">
                <Row type="flex" justify="space-around" class="code-row-bg">
                    <Col span="6" push="9" class-name="zixingg" @click.native="shouye()">首页</Col>
                    <Col span="6" push="4" class-name="zixing">>专题</Col>
                    <Col span="6" class-name="zi">>专题详情</Col>
                </Row>

                <div>
                    <h1>{{tit}}</h1>
                    <div class="laiyuan">
                        <div class="lai">来源：{{subjectResource}} </div>
                        <div class="shijian">时间：{{ time}}</div>
                        <!-- <div class="bianxing">
                            <div>字体:</div>
                            <div class="da">
                                <div @click="bianda" ref="da" :style="{fontSize:cur + 'px'}">[大</div>
                                <div @click="zhong" ref="zhjong" :style="{fontSize:z + 'px'}">中</div>
                                <div @click="xiao" ref="xiao" :style="{fontSize:xia + 'px'}">小]</div>
                            </div>
                        </div> -->
                    </div>
                    <div class="hengxian"></div>
                    <div class="content">
                        <div class="tv" v-show="vide==1">
                            <video :src="videoUrl" controls class="vide"></video>
                        </div>

                        <div class="neir" :style="{fontSize:num + 'px'}" v-html="subjectContent"></div>
                    </div>
                    <div class="hengxian"></div>
                </div>
                <div class="dibu">
                    <div v-for="(item,index) in filenameArr" :key="index">
                        <a :href="accur" class="pujian" ref="fujian" @click="accurl(index)"
                            :style="{display:fileUrl==''?'none':'block'}">附件:{{item}}</a>
                    </div>
                    <div class="btn">
                        <Button type="primary" @click="dayin()">打印本页</Button>
                        <Button type="primary" @click="out()">关闭</Button>
                    </div>
                </div>


            </div>
        </div>
        <div id="print-iframe" ref="printIframe" style="display:none">
            <div class="ggdetailbox">
                <div><span>首页</span><span>>信息公开</span><span>>专题详情</span></div>
                <div>
                    <h1>{{tit}}</h1>
                    <div class="laiyuan">
                        <div class="lai">来源：{{subjectResource}} </div>
                        <div class="shijian">时间：{{ time}}</div>
                        <!-- <div class="bianxing">
                            <div>字体:</div>
                            <div class="da">
                                <div @click="bianda" ref="da" :style="{fontSize:cur + 'px'}">[大</div>
                                <div @click="zhong" ref="zhjong" :style="{fontSize:z + 'px'}">中</div>
                                <div @click="xiao" ref="xiao" :style="{fontSize:xia + 'px'}">小]</div>
                            </div>
                        </div> -->
                    </div>
                    <div class="hengxian"></div>
                    <div class="content">
                        <div class="tv" v-show="vide==1">
                            <video :src="videoUrl" controls class="vide"></video>
                        </div>

                        <div class="neir" :style="{fontSize:num + 'px'}" v-html="subjectContent"></div>
                    </div>
                    <div class="hengxian"></div>
                </div>
                <div class="dibu">
                    <div v-for="(item,index) in filenameArr" :key="index">
                        <a :href="accur" class="pujian" ref="fujian" @click="accurl(index)"
                            :style="{display:fileUrl==''?'none':'block'}">附件:{{item}}</a>
                    </div>
                </div>
            </div>
            <div id="fangzhi" style="display: none"></div>
        </div>
    </div>
</template>
<script>
    import { queryPageNews } from "../../woke/xinx"
    import { formatDate } from '../../commm/time.js'
    export default {
        data() {
            return {
                content: [],
                num: 16,
                cur: "",
                z: 18,
                xia: "",
                id: "",
                curPage: 1,
                maxPage: 1,
                gonggao: [],
                tit: "",
                time: "",
                subjectResource: "",
                subjectContent: "",
                fileUrl: "",
                fileName: "",
                videoUrl: "",
                vide: 2,
                 filenameArr: [],
                imgUrlArr: [],
                accur: ""
            }
        },
        created() {
            this.queryPageNewsqwe()
        },
        activated() {

        },
        methods: {
             dayin() {
                this.$nextTick().then((res) => {
                    setTimeout(() => {
                    let iframe = document.createElement("iframe");
                    fangzhi.appendChild(iframe);
                    let wrap = this.$refs.printIframe.innerHTML;
                    let doc = iframe.contentWindow.document;
                    doc.write("<div>" + wrap + "</div>");
                    doc.close();
                    iframe.contentWindow.print();
                    iframe.contentWindow.focus();
                    this.flag = true;
                    }, 200);
                });
            },
            bianda() {
                this.cur = 18
                this.num = 20
                this.z = 14
                this.xia = 14
            },
            zhong() {

                this.num = 16
                this.z = 18
                this.cur = 14
                this.xia = 14
            },
            xiao() {
                this.num = 14
                this.xia = 18
                this.z = 14
                this.cur = 14
            },
            out() {
                this.$router.go(-1)
            },
            shouye() {
                this.$router.push("/index")
            },
            //公告
            queryPageNewsqwe() {
             
                this.tit = this.$route.params.tit
                this.time = this.$route.params.time
                this.subjectResource = this.$route.params.subjectResource
                this.subjectContent = this.$route.params.subjectContent
                this.fileUrl = this.$route.params.fileUrl
                this.fileName = this.$route.params.fileName,
                    // console.log(this.subjectResource, this.subjectContent, this.fileUrl, this.fileName );
                    this.videoUrl = this.$route.params.videoUrl
                if (this.videoUrl == "" || this.videoUrl == null) {
                    this.vide = 2
                } else {
                    this.vide = 1
                }

                this.filenameArr = this.fileName.split(",");
                this.imgUrlArr = this.fileUrl.split(",");
                // console.log(this.filenameArr);
                this.filenameArr.forEach((item, index) => {
                    if (item == "") {
                        this.filenameArr.splice(index, 1)
                    }
                })
                this.imgUrlArr.forEach((item, index) => {
                    if (item == "") {
                        this.imgUrlArr.splice(index, 1)
                    }
                })
            },
            accurl(index) {
                this.imgUrlArr.forEach((item, i) => {
                    this.accur = this.imgUrlArr[index]
                })
            },
        },
        mounted() {

        },
        filters: {
            /* 时间格式自定义 只需把字符串里面的改成自己所需的格式*/
            formatDate(time) {
                var date = new Date(time);
                return formatDate(date, 'yyyy-MM-dd');
            },

        }
    }
</script>
<style scoped>
#printTest {
    min-height: 100vh;
    margin-bottom: -60px;
    box-sizing: border-box;
    padding-top: 30px;
    padding-bottom: 60px;
    margin-left: 60px;

}

.code-row-bg {
    width: 310px;
    margin-left: 240px;
}

.zixing {
    margin-top: 20px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #4D4D4D;
    line-height: 21px;
}

.zixingg {
    margin-top: 20px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #4D4D4D;
    line-height: 21px;
    cursor: pointer;
}

.zi {
    margin-top: 21px;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #304C87;
    line-height: 21px;
}

.laiyuan {
    width: 1100px;
    display: flex;
    justify-content: space-around;
    margin-left: 310px;
    margin-top: 20px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #1A1A1A;
    line-height: 19px;
}

.da {
    width: 100px;
    display: flex;
    justify-content: space-around;

}

.bianxing {
    width: 150px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-right: -200px;
}

h1 {
    font-size: 24px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #1A1A1A;
    line-height: 31px;
    margin-top: 50px;
    text-align: center;
    /* margin-left: 800px; */
}

.hengxian {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 1100px;
    height: 1px;
    background-color: #005CB0;
    overflow: hidden;
    margin-left: 370px;
}

.neir {
    width: 1080px;
    margin-left: 380px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #4D4D4D;
}

.lai {
    margin-left: -70px;
}

.dibu {
    width: 1100px;
    /* display: flex;
    justify-content: space-between; */
    margin-top: 80px;
    margin-left: 360px;
    margin-bottom: 30px;
    position: relative;
}

.btn {
    width: 180px;
    display: flex;
    justify-content: space-around;
    position: absolute;
    top: -26px;
    right: -18px;

}

.dayin {
    width: 140px;
    height: 46px;
    border: 1px solid #005CB0;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #005CB0;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
}

.getout {
    width: 140px;
    height: 46px;
    border: 1px solid #005CB0;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #005CB0;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
}

.pujian {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #2D8CF0;
    line-height: 21px;
    cursor: pointer;
    /* margin-left: 370px; */
}

.shijian {
    margin-left: -300px;
}

.vide {
    width: 520px;
    height: 292px;

}

.tv {
    width: 1080px;
    height: 300px;
    text-align: center;
    margin-left: 380px;
    margin-bottom: 10px;
}
</style>